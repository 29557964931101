/* Footer.css */

/* Overall footer container */
.footer-container {
    background-color: #f5f5f5;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    height: 100px;
  }
  
  /* Left side (icons and contact info) */
  .footer-left {
    display: flex;
    align-items: center;
  }
  
  /* Social media icons */
  .footer-icons a {
    color: #333;
    margin-right: 10px;
    font-size: 14px;
    text-decoration: none;
  }
  
  /* Email and phone links */
  .footer-contact a {
    color: #555;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
  }

  
  /* Right side (text) */
  .footer-right {
    text-align: right;
    color: #777;
  }
  
  /* Owner/operator text */
  .footer-text {
    font-weight: bold;
  }
  
  /* Responsive adjustments for smaller screens */
  @media screen and (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
    }
    .footer-left {
      margin-bottom: 20px;
    }
  }
  