.pageTitle {
    font-weight: bold;
    font-size: 50px;
}

.contact-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-item {
    width: 48%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-item strong {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .hoursSideBySide {
    display: flex;
    justify-content: space-between;
  }

  .itemTitle {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .centered {
    text-align: center;
  }

  .hrefClick {
    color: white;
    text-decoration: underline;
    transition: 0.3s;
  }

  .hrefClick:hover {
    font-weight: bold;
  }

@media only screen and (max-width: 715px) {
    .contact-page {
        display: block;
    }

    .contact-item {
        width: 100%;
    }
  }