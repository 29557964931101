.navBar {
  height: 75px;
}

.App {
  padding-top: 40px;
  min-height: calc(100vh - 150px);
  background-color: #2c2c2c;
  color: white;
}

.wrap {
  width: 80%;
  /* max-width: 24em; */
  margin: 0 auto;
  padding: 0.25em 0.625em;
}
