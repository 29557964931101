/* Reviews.css */

.reviews-container {
  padding: 20px;
  background-color: #2c2c2c;
  color: #ffffff;
  border-radius: 8px;
}

.our-reviews-title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
}

.reviews-heading {
  font-size: 24px;
  margin-bottom: 16px;
}

.reviews-grid {
  display: flex;
  flex-wrap: wrap;
  
  gap: 20px;
}

.review-item {
  flex: 0 0 calc(33.333% - 20px); /* Adjust the gap as needed */
  border: 1px solid #444;
  padding: 12px;
  border-radius: 4px;
  background-color: #333;
}

.review-name {
  font-weight: bold;
}

.review-rating {
  color: #00cc66;
}

.review-text {
  margin-top: 8px;
}

.floating-button {
  position: fixed;
  bottom: 120px; /* Adjust this value as needed */
  right: 20px; /* Adjust this value as needed */
  padding: 10px 20px; /* Add padding to fit the text */
  font-size: 14px; /* Adjust font size as desired */
  /* Other styles (background color, border, etc.) */
  background-color: #474747;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Hover transition */
  border-radius: 10px;
}

.floating-button:hover {
  background-color: #404040;
}

.reviewDate {
  font-size: 12px;
  color: #999;
}


@media only screen and (max-width: 715px) {
  .reviews-grid {
    flex-direction: column;
  }
  
  .review-item {
    flex: 0 0 calc(100% - 20px);
  }
}
