.home-container {
  padding: 20px;
  background-color: #2c2c2c;
  color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.homeLogo {
  height: 200px;
  width: 200px;
  border-radius: 10%;
  margin-right: 50px;
}

.homeTitle {
  font-size: 30px;
  font-weight: bold;
}

.homeSubTitle {
  font-size: 24px;
  font-weight: bold;
}

.slideshowTitle {
  font-size: 24px;
  font-weight: bold;
  margin-top: 25px;
  text-align: center;
}

.photoSlideShow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.slideshow-img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.itemDesc {
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
}

.imageSlider {
  height: 500px;
}

@media only screen and (max-width: 615px) {
  .home-container {
    display: block;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .homeLogo {
    margin-right: 0;
  }

  .photoSlideShow {
    display: block;
  } 
}
