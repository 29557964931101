/* styles.css */
.centered-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Adjust as needed */
}

/* styles.css */
.cs-container {
  text-align: left;
  font-family: Arial, sans-serif;
  display: flex;
  max-width: 80%;
}

.side-by-side {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin: 20px;
}

.pageLogo {
  width: 300px;
  height: 300px;
}

/* Media query for screens with a maximum width of 600px (adjust as needed) */
@media (max-width: 600px) {
  .cs-container {
    flex-direction: column;
  }

  .pageLogo {
    width: 200px;
    height: 200px;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  
}
