.navTitle {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.sloganText {
    font-size: 11px;
    color: white;
}

.navItem {
    color: white;
    transition: 100ms;
    margin: 10px;
    text-decoration: none;
}

.navItem:hover {
    font-weight: bold;
}

.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
    
}

.flex-child:first-child {
    margin-right: 20px;
}

.navLogo {
    height: 50px;
    width: 50px;
    border-radius: 10%;
}

.wholeNav {
    height: 75px;
    background-color: white;
    border-bottom: 3px white solid;
}

Link {
    text-decoration: none;
    color: white;
    text-decoration: none;
}