.services-container {
  text-align: center;
  padding: 20px;
  background-color: #2c2c2c;
  color: #ffffff;
  border-radius: 8px;
}

.services-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.service-item {
  flex: 0 0 calc(33.333% - 20px); /* Adjust the gap as needed */
  border: 1px solid #444;
  padding: 12px;
  border-radius: 4px;
  background-color: #333;
  margin: 10px;
}

.service-item-title {
  /* display: flex; */
  /* align-items: center;  */
  /* display: inline-block; */
  /* margin-left: 2.5px;
  margin-right: 2.5px; */
  margin: 10px;
}

.service-title {
  font-size: 18px;
  font-weight: bold;
}

.service-description {
  font-size: 14px;
  color: #d5d5d5;
}

@media only screen and (max-width: 715px) {
    .services-list {
        flex-direction: column;
    }
    
    .service-item {
        flex: 0 0 calc(100% - 20px);
    }
}
